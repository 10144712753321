import GameInterface from "@homegame/common/dist/interface/game.interface";
import Chip from "@mui/material/Chip";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { capitalize } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ClearIcon from "@mui/icons-material/Clear";
import GradeIcon from "@mui/icons-material/Grade";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import GameStatus from "@homegame/common/dist/enum/game-status.enum";
import { useAuth } from "../core/context/auth.context";
import GamePlayerStatus from "@homegame/common/dist/enum/game-player-status.enum";
import { OverridableStringUnion } from "@mui/types";
import { ChipPropsColorOverrides } from "@mui/material/Chip/Chip";
import { ReactElement } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      marginTop: 5,
      fontSize: 11,
      fontWeight: 300,
      height: 18,
      borderRadius: 5,
      clear: "both",
      maxWidth: "fit-content",
      "& .MuiChip-icon": {
        color: "#fff",
        fontSize: 11,
        marginTop: -1,
      },
    },
  }),
);

export default function GameStatusComponent({ game }: { game: GameInterface }) {
  const classes = useStyles({});
  const { user } = useAuth();

  const currentGamePlayer = game.players?.find((p) => p.playerId === user?.id);

  let color: OverridableStringUnion<
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning",
    ChipPropsColorOverrides
  > = "info";
  let label: string = game.status;
  let icon: ReactElement<any> = <></>;
  if (game.status === GameStatus.FINISHED) {
    label = "Completed";
    color = "default";
    icon = <DoneIcon />;
  }
  if (game.status === GameStatus.CANCELED) {
    color = "secondary";
    icon = <ClearIcon />;
  }
  if (game.status === GameStatus.ONGOING) {
    color = "success";
    label = "Running";
    icon = <AutorenewIcon />;
  }
  if (game.status === GameStatus.NEW) {
    icon = <GradeIcon />;
    if (currentGamePlayer && currentGamePlayer.status) {
      if (currentGamePlayer.status === GamePlayerStatus.INVITED) {
        label = "New invite";
        icon = <HourglassFullIcon />;
      }
      if (currentGamePlayer.status === GamePlayerStatus.REGISTERED) {
        label = "Registered";
        icon = <DoneIcon />;
      }
      if (currentGamePlayer.status === GamePlayerStatus.UNREGISTERED) {
        label = "Declined";
        color = "error";
        icon = <ClearIcon />;
      }
    }
  }
  return (
    <Chip
      className={classes.chip}
      icon={icon}
      color={color}
      label={capitalize(label)}
    />
  );
}
