import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import TableService from "../core/service/table.service";
import TableListStyle from "./styles/table-list.style";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import TableComponent from "../core/components/table.component";
import { useSocket } from "../core/context/socket.context";
import useScreenSize from "../core/hooks/use-screen-size";
import useUserOs from "../core/hooks/use-user-os";
import { useSocketSubscription } from "../core/hooks/use-web-socket-subscription.hook";
import GameStatus from "@homegame/common/dist/enum/game-status.enum";
import UserService from "../core/service/user.service";
import { useAuth } from "../core/context/auth.context";
import Chip from "@mui/material/Chip";
import TablePlayerStatus from "@homegame/common/dist/enum/table-player-status.enum";
import GamePlayerInterface from "@homegame/common/dist/interface/game-player.interface";

export default function TableListComponent({
  searchVal,
}: {
  searchVal?: string;
}) {
  const screenSize = useScreenSize();
  const { isIOS } = useUserOs();

  const { user } = useAuth();

  const innerContainerSize = screenSize.height - (isIOS ? 240 : 225); // magic numbers

  const navigate = useNavigate();
  const classes = TableListStyle({});
  const tableService = new TableService();
  const userService = new UserService();
  const [tableFilters, setTableFilters] = useState({
    searchString: searchVal,
    take: Math.floor((innerContainerSize - 100) / 68),
    skip: 0,
  });
  const [gameInvites, setGameInvites] = useState<GamePlayerInterface[]>([]);
  const { tableSocket } = useSocket();

  useEffect(() => {
    setTableFilters({
      ...tableFilters,
      searchString: searchVal,
      take: tableFilters.take,
      skip: 0,
    });
    if (user && user.id)
      userService.getGameInvites(user.id).then((d) => setGameInvites(d.data));
  }, [searchVal]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "",
      flex: 1,
      hideable: false,
      sortable: false,
      headerClassName: "hidden",
      renderCell: (params: GridRenderCellParams) => {
        const currentTable = params.row;

        const hasOngoingGame = currentTable.games.some(
          (game: any) => game.status === GameStatus.ONGOING,
        );

        const hasPendingTableInvite = currentTable.players.some(
          (player: any) =>
            player.status === TablePlayerStatus.PENDING &&
            player.playerId === user!.id,
        );

        const tableGameIds = currentTable.games.map((game: any) => game.id);
        const hasPendingGameInvite = gameInvites?.some((gameInvite) =>
          tableGameIds.includes(gameInvite.gameId),
        );

        return (
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.pos} color="textSecondary">
                {currentTable.name}
              </Typography>
              <Typography className={classes.subpos} color="textSecondary">
                {currentTable.players ? currentTable.players.length : 0} players
              </Typography>
            </Grid>
            <Grid item>
              {hasOngoingGame && (
                <Chip
                  className={classes.chip}
                  color="success"
                  label="Ongoing game"
                  icon={<AutorenewIcon />}
                />
              )}
              {hasPendingTableInvite ||
                (hasPendingGameInvite && (
                  <Chip
                    className={classes.chip}
                    color="info"
                    label="New invite"
                    icon={<HourglassFullIcon />}
                  />
                ))}
            </Grid>
          </Grid>
        );
      },
    },
  ];

  useSocketSubscription(tableSocket?.connect(), [
    {
      eventName: "tableCreated",
      callback: () => setTableFilters(tableFilters),
    },
    {
      eventName: "tableUpdated",
      callback: () => setTableFilters(tableFilters),
    },
  ]);

  const handleRowClick = (params: any) => {
    const currentTable = params.row;
    const ongoingGame = currentTable.games.find(
      (game: any) => game.status === GameStatus.ONGOING,
    );

    const tableGameIds = currentTable.games.map((game: any) => game.id);
    const hasPendingGameInvite = gameInvites.find((gameInvite) =>
      tableGameIds.includes(gameInvite.gameId),
    );

    if (ongoingGame) {
      navigate(`/game/${ongoingGame.id}`);
    } else if (hasPendingGameInvite) {
      navigate(`/game/${hasPendingGameInvite.gameId}`);
    } else navigate(`/tables/${params.row.id}/view`);
  };

  return (
    <Grid item xs={12} className={classes.wrapper}>
      <TableComponent
        height={innerContainerSize}
        columns={columns}
        service={tableService}
        filters={tableFilters}
        onRowClick={handleRowClick}
      />
    </Grid>
  );
}
