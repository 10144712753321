import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import formatCurrency from "../../core/helpers/format-currency.helper";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import { useQuery } from "@tanstack/react-query";
import UserDebtsService from "../../core/service/user-debts.service";
import { useAuth } from "../../core/context/auth.context";
import AccumulatedDebtInterface from "@homegame/common/dist/interface/accumulated-debt.interface";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import UserDebtInterface from "@homegame/common/dist/interface/user-debt.interface";
import UserDebtStatus from "@homegame/common/dist/enum/user-debt-status.enum";
import DebtType from "@homegame/common/dist/enum/debt-type.enum";
import DebtPaymentMethod from "@homegame/common/dist/enum/debt-payment-method.enum";
import TransactionStatus from "@homegame/common/dist/enum/transaction-status.enum";

interface DebtInfoDialogProps {
  type?: "users" | "to-user";
  debt: AccumulatedDebtInterface;
  handleDebtDialogClose: () => void;
  open: boolean;
}

interface DebtHistoryEntry extends UserDebtInterface {
  description: string;
  amountLost: number;
  amountWon: number;
  total: number;
  gameId: number;
  currency: string;
  createdAt?: Date;
}

const useStyles = makeStyles(() =>
  createStyles({
    tableHead: {
      color: "#47528c",
      textShadow: "none",
      fontSize: 13,
      padding: 5,
      lineHeight: "18px",
      verticalAlign: "bottom",
    },
    tableItem: {
      color: "#fff",
      textShadow: "none",
      fontSize: 15,
      padding: 5,
    },
  }),
);

export default function DebtInfoDialogComponent({
  debt,
  handleDebtDialogClose,
  type,
  open,
}: DebtInfoDialogProps) {
  const classes = useStyles({});
  const userDebtsService = new UserDebtsService();
  const { user } = useAuth();

  const whereOpts = {
    fromUserId: type === "users" ? user!.id! : debt.fromUserId,
    toUserId: type === "users" ? debt.toUserId : user!.id!,
    tableId: debt.tableId,
  };

  const { data: userDebtsInfo } = useQuery({
    queryKey: [`debtsHistoryItems1`, whereOpts],
    queryFn: () => userDebtsService.userDebts(whereOpts),
    initialData: [],
  });

  let totalBalance = 0;

  const entries: DebtHistoryEntry[] = [];

  const getDebtDescriptionText = (debt: UserDebtInterface): string => {
    const { type: debtType, fromUserId } = debt;
    const showDebugText = true;

    const isViewerDebtor = fromUserId === user!.id;

    const debtorName = debt.fromUser?.name || "Unknown";
    const creditorName = debt.toUser?.name || "Unknown";

    if (
      debt.type === DebtType.DEBT &&
      (debt.parent?.paymentMethod === "debt_transfer" ||
        debt.paymentMethod === "debt_transfer")
    ) {
      const relatedDebt =
        debt.parent ||
        debt.children?.find((child) => child.paymentMethod === "debt_transfer");

      if (relatedDebt) {
        const originalDebtor = relatedDebt.fromUser?.name || "Unknown";
        const finalCreditor = debt.toUser?.name || "Unknown";

        return isViewerDebtor
          ? `Debt transferred to ${isViewerDebtor ? originalDebtor : finalCreditor} (${relatedDebt.toUser?.name}). ${showDebugText ? `GAME ID: ${debt.gameId}, DEBT ID: ${debt.id}` : ""}`
          : `Debt transferred from ${originalDebtor} (${relatedDebt.toUser?.name}). ${showDebugText ? `GAME ID: ${debt.gameId}, DEBT ID: ${debt.id}` : ""}`;
      }
    }

    if (
      debt.type === DebtType.PAYMENT &&
      debt.status === UserDebtStatus.CLOSED &&
      debt.paymentMethod === DebtPaymentMethod.CASH
    ) {
      return isViewerDebtor
        ? `I paid ${creditorName}.${showDebugText ? ` GAME ID: ${debt.gameId}, DEBT ID: ${debt.id}` : ""}`
        : `${debtorName} paid me.${showDebugText ? ` GAME ID: ${debt.gameId}, DEBT ID: ${debt.id}` : ""}`;
    }

    return debtType === DebtType.DEBT
      ? isViewerDebtor
        ? `I lost in game to ${creditorName}.${showDebugText ? ` GAME ID: ${debt.gameId}, DEBT ID: ${debt.id}` : ""}`
        : `I won in game from ${debtorName}.${showDebugText ? ` GAME ID: ${debt.gameId}, DEBT ID: ${debt.id}` : ""}`
      : isViewerDebtor
        ? `I won in game from ${creditorName}.${showDebugText ? ` GAME ID: ${debt.gameId}, DEBT ID: ${debt.id}` : ""}`
        : `I lost in game to ${debtorName}.${showDebugText ? ` GAME ID: ${debt.gameId}, DEBT ID: ${debt.id}` : ""}`;
  };

  const getEntryRowStyles = (entry: DebtHistoryEntry) => {
    let bgColor = "inherit";
    if (entry.amountWon > 0) {
      bgColor = "#377a3a";
    } else if (entry.amountLost > 0) {
      bgColor = "#aa2e26";
    }
    return { backgroundColor: bgColor };
  };

  const showEntryDate = (entry: DebtHistoryEntry) => {
    return <>{dayjs(entry.createdAt).format("D MMMM YYYY")}</>;
  };

  const getDebtHistoryEntries = (debt: UserDebtInterface) => {
    const isViewerDebtor = debt.fromUserId === user!.id;
    const isViewerCreditor = debt.toUserId === user!.id;
    const isDebtTransfer = debt.paymentMethod === "debt_transfer";

    const sign = isViewerDebtor ? -1 : 1;
    const amount = debt.originalAmount;

    const findExistingEntry = () =>
      entries.find(
        (entry) =>
          entry.gameId === debt.gameId &&
          debt.paymentMethod !== DebtPaymentMethod.CASH &&
          debt.parent?.paymentMethod === "debt_transfer",
      );

    const addEntry = (
      amountLost: number,
      amountWon: number,
      updatedTotal: number,
      createdAt: Date | undefined,
    ) => {
      entries.push({
        ...debt,
        description: getDebtDescriptionText(debt),
        amountLost,
        amountWon,
        total: updatedTotal,
        createdAt,
      });
    };

    if (debt.type === DebtType.DEBT && !debt.parentId) {
      if (!isDebtTransfer) {
        totalBalance += sign * amount;
      }

      const existingEntry = findExistingEntry();

      if (existingEntry) {
        existingEntry.amountLost += isViewerDebtor ? amount : 0;
        existingEntry.amountWon += isViewerCreditor ? amount : 0;
        existingEntry.total += sign * amount;
        existingEntry.createdAt = debt.game?.startedAt || debt.createdAt;
      } else {
        addEntry(
          isViewerDebtor ? amount : -amount,
          isViewerCreditor ? amount : -amount,
          totalBalance,
          debt.game?.startedAt || debt.createdAt,
        );
      }
    }

    if (debt.type === DebtType.DEBT && debt.parentId) {
      totalBalance += sign * amount;
      entries.push({
        ...debt,
        description: getDebtDescriptionText(debt),
        amountLost: isViewerDebtor ? amount : -amount,
        amountWon: isViewerCreditor ? amount : -amount,
        total: totalBalance,
        createdAt: debt.game?.startedAt || debt.createdAt,
      });
    }

    if (debt.type === DebtType.PAYMENT) {
      const isGameOffset = debt.paymentMethod === DebtPaymentMethod.GAME_OFFSET;
      const isDebtTransfer = debt.paymentMethod === "debt_transfer";
      if (isDebtTransfer) return;

      totalBalance -= sign * amount;

      const existingEntry = isGameOffset ? findExistingEntry() : null;

      let paymentDate = debt.createdAt;
      if (debt.parent?.transaction) {
        const { status, approvedAt, declinedAt, updatedAt } =
          debt.parent.transaction;

        if (status === TransactionStatus.APPROVED && approvedAt) {
          paymentDate = approvedAt!;
        } else if (status === TransactionStatus.DECLINED && declinedAt) {
          paymentDate = declinedAt!;
        } else paymentDate = updatedAt;
      } else if (debt.game) {
        paymentDate = debt.game.startedAt;
      }

      if (existingEntry) {
        existingEntry.amountLost += isViewerCreditor ? amount : 0;
        existingEntry.amountWon += isViewerDebtor ? amount : 0;
        existingEntry.total -= sign * amount;
        existingEntry.createdAt = paymentDate;
      } else {
        addEntry(
          isViewerCreditor ? amount : -amount,
          isViewerDebtor ? amount : -amount,
          totalBalance,
          paymentDate,
        );
      }
    }
  };

  userDebtsInfo.forEach(getDebtHistoryEntries);

  entries.reverse();

  const totalBalanceString = () => {
    const isCreditorView = debt.toUserId === user!.id;
    const amount = debt.accumulatedDebt;
    const isPositive = amount > 0;
    const isZero = amount === 0;

    let color = isZero
      ? "#47528c"
      : isCreditorView === isPositive
        ? "green"
        : "red";
    let sign = isZero ? "" : isCreditorView === isPositive ? "+" : "-";

    return (
      <span style={{ color }}>
        {sign}
        {formatCurrency(Math.abs(amount), debt.currency)}
      </span>
    );
  };

  return (
    <Dialog
      onClose={handleDebtDialogClose}
      open={open}
      sx={{
        "& .MuiDialogContent-root": { padding: 0 },
        "& .MuiPaper-root": { backgroundColor: "#aab1db" },
        "& .MuiDialogTitle-root": {
          color: "#47528c",
          textShadow: "none",
          textAlign: "center",
          fontWeight: 400,
          marginTop: "10px",
        },
      }}
    >
      <DialogTitle>
        {type === "users" && `Me/${debt.toUser?.name} balance`}
        {type === "to-user" && `${debt.fromUser?.name}/Me balance`} (
        {totalBalanceString()})
        <br />
        <small style={{ color: "white" }}>In {debt.table.name}</small>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleDebtDialogClose}
        sx={{
          position: "absolute",
          right: 0,
          top: -5,
          color: "#47528c",
          textShadow: "none",
          boxShadow: "none",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid
          container
          spacing={2}
          style={{ marginLeft: 0, width: "100%", marginTop: 0 }}
        >
          {userDebtsInfo.length > 0 && (
            <>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{}}>
                      <TableCell
                        className={classes.tableHead}
                        style={{ paddingLeft: 10 }}
                        align="left"
                      >
                        Description
                      </TableCell>
                      <TableCell className={classes.tableHead} align="left">
                        <b>-</b>
                      </TableCell>
                      <TableCell className={classes.tableHead} align="left">
                        <b>+</b>
                      </TableCell>
                      <TableCell className={classes.tableHead} align="left">
                        Total
                        <br />
                        balance
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entries.map((entry) => (
                      <TableRow
                        key={dayjs(entry.createdAt).valueOf() + entry.total}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          ...getEntryRowStyles(entry),
                        }}
                      >
                        <TableCell
                          className={classes.tableItem}
                          style={{ fontSize: 12, paddingLeft: 10 }}
                          align="left"
                        >
                          <b>{showEntryDate(entry)}</b>
                          <br />
                          {entry.description}
                        </TableCell>
                        <TableCell className={classes.tableItem} align="left">
                          {entry.amountLost > 0
                            ? `${formatCurrency(
                                entry.amountLost,
                                entry.currency,
                              )}`
                            : ""}
                        </TableCell>
                        <TableCell className={classes.tableItem} align="left">
                          {entry.amountWon > 0
                            ? `${formatCurrency(
                                entry.amountWon,
                                entry.currency,
                              )}`
                            : ""}
                        </TableCell>
                        <TableCell className={classes.tableItem} align="left">
                          {formatCurrency(entry.total, entry.currency)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
