import React, { memo } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import LoadingButton from "./loading-button.component";
import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      zIndex: 999,
      // background: '#fff',
    },
  }),
);

const ConfirmDialog = memo(
  ({
    text,
    actionText = "Delete",
    cancelText = "Cancel",
    isLoading = false,
    open,
    onClose,
    action,
  }: {
    text: string | undefined;
    actionText?: string | undefined;
    cancelText?: string | undefined;
    open: boolean;
    isLoading?: boolean;
    onClose: () => void;
    action: () => any;
  }) => {
    const classNames = useStyles();

    return (
      <Dialog
        open={open}
        onClose={() => onClose()}
        classes={{
          paper: classNames.dialogPaper,
        }}
      >
        <DialogContent>
          <DialogContentText>
            <Typography component="span">{text}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            text={actionText}
            isLoading={isLoading}
            onClick={action}
            color="success"
            variant="contained"
          />
          <Button
            onClick={() => onClose()}
            style={{ backgroundColor: "#4d589c" }}
            autoFocus
            variant="contained"
          >
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default ConfirmDialog;
