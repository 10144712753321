import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import GameDuration from "./game-duration";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import PlayerGameBalance from "./player-game-balance";
import PlayerStatusComponent from "./player-status.component";
import GamePlayerStatus from "@homegame/common/dist/enum/game-player-status.enum";
import InviteControls from "./components/player-invite-controls.component";
import PlayerDeleteBtn from "./components/player-delete-btn.component";
import PlayerTransactionsBtns from "./player-transactions-btns.component";
import { useGame } from "./game.context";
import CardComponent from "./components/cards/card.component";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TransactionsListComponent from "../transactions/transactions-list.component";
import MakeCashierBtnComponent from "./components/make-cashier-btn.component";

const useStyles = makeStyles(() =>
  createStyles({
    closeBtn: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    playerName: {
      position: "relative",
      fontWeight: 500,
      fontSize: "1.2rem",
      "& .MuiChip-root": {
        right: "auto",
      },
    },
    transactionsTitle: {
      color: "#47528c",
      textShadow: "none",
      textAlign: "center",
      marginBottom: 10,
    },
    transactionsWrapper: {
      backgroundColor: "rgba(255, 255, 255, 0.6)",
      padding: 15,
      borderRadius: 15,
      marginBottom: 15,
    },
  }),
);

const DIVIDER_MARGIN = 0;

/**
 * Renders the game information for a specific player.
 *
 * @param game - The game object.
 * @param user - The user object.
 * @param isDialog - Optional. Indicates whether the component is rendered as a dialog. Default is false.
 * @param onTransactionSent - Optional. Callback function triggered when a transaction is sent.
 * @param onCloseBtnClick - Optional. Callback function triggered when the close button is clicked.
 * @param onPlayerDelete - Optional. Callback function triggered when a player is deleted.
 * @param onPlayerStatusChange
 * @returns The rendered component.
 */
export default function GameInfoPlayer({
  user,
  isDialog = false,
  onTransactionSent,
  onCloseBtnClick,
  onPlayerDelete,
  onPlayerStatusChange,
}: {
  user: UserInterface | undefined;
  isDialog?: boolean;
  onPlayerDelete?: () => void;
  onPlayerStatusChange?: () => void;
  onTransactionSent?: () => void;
  onCloseBtnClick?: () => void;
}) {
  const { isCashier, isNew, isOngoing, isCanceled, game } = useGame();
  const classes = useStyles();

  if (!game || !user || isCanceled) return <></>;

  const currentGamePlayer = game.players?.find((p) => p.playerId === user.id);

  return (
    <>
      <Grid container spacing={1}>
        {currentGamePlayer && (
          <InviteControls
            currentGamePlayer={currentGamePlayer}
            user={user}
            onPlayerStatusChange={onPlayerStatusChange}
          />
        )}

        {isDialog && (
          <>
            <Grid item xs={12} position="relative">
              <Typography align="center" className={classes.playerName}>
                {user.name}
                {currentGamePlayer && (
                  <PlayerStatusComponent
                    table={game.table}
                    gamePlayer={currentGamePlayer}
                  />
                )}
              </Typography>
              {isDialog && (
                <IconButton
                  className={classes.closeBtn}
                  onClick={onCloseBtnClick}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: DIVIDER_MARGIN, mb: DIVIDER_MARGIN }} />
            </Grid>
          </>
        )}

        {currentGamePlayer?.status === GamePlayerStatus.REGISTERED && (
          <>
            {!isDialog && !isNew && !isOngoing && (
              <>
                <Grid item xs={12}>
                  <CardComponent
                    icon={<AccessTimeIcon style={{ color: "#000" }} />}
                    primary={<GameDuration game={game} textVariant="body1" />}
                    secondary="Game duration"
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} mt={2}>
              <div className={classes.transactionsWrapper}>
                <Typography variant="h5" className={classes.transactionsTitle}>
                  Transactions
                </Typography>
                <TransactionsListComponent
                  showStatus
                  pageSize={4}
                  game={game}
                  userId={user?.id}
                  onActionDone={onTransactionSent}
                />
              </div>
            </Grid>

            <PlayerGameBalance user={user} />
          </>
        )}

        {(isOngoing || isNew) && isCashier && (
          <>
            <Grid item xs={12}>
              <Divider sx={{ mt: DIVIDER_MARGIN, mb: DIVIDER_MARGIN }} />
            </Grid>
            <Grid
              item
              xs={12}
              textAlign="center"
              justifyContent="space-around"
              display="flex"
              p={1}
            >
              <PlayerTransactionsBtns
                user={user}
                onTransactionSent={onTransactionSent}
              />
              {currentGamePlayer?.status === GamePlayerStatus.REGISTERED &&
                user.id !== game.cashierId && (
                  <MakeCashierBtnComponent
                    user={user}
                    onDone={onPlayerDelete}
                  />
                )}
              <PlayerDeleteBtn user={user} onPlayerDelete={onPlayerDelete} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
