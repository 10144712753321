import { useAuth } from "../core/context/auth.context";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import useGameInfo from "../core/hooks/useGameInfo/use-game-info.hook";
import getPlayerLastTransaction from "../core/hooks/useGameInfo/get-player-last-transaction";
import TransactionStatus from "@homegame/common/dist/enum/transaction-status.enum";
import { useGame } from "./game.context";
import formatCurrency from "../core/helpers/format-currency.helper";
import CardComponent from "./components/cards/card.component";
import { Grid } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadIcon from "@mui/icons-material/Upload";
import CardOwesComponent from "./components/cards/card-owes.component";
import CardWinLostComponent from "./components/cards/card-win-lost.component";

export default function PlayerGameBalance({
  user,
}: {
  user: UserInterface | undefined;
}) {
  const { user: loggedInUser } = useAuth();

  const { game } = useGame();
  const { playersGameStats, gameRate } = useGameInfo(game);

  if (!user || !game || !loggedInUser) return <></>;

  const gamePlayersBalance = playersGameStats.find(
    (playerInfo) => playerInfo.id === user.id,
  );

  if (!gamePlayersBalance) return <></>;

  const { debtToTable, amountWonLost, totalBuyIn } = gamePlayersBalance;

  const lastPlayerTransaction = getPlayerLastTransaction(
    user.id!,
    game.transactions,
  );

  const isPlayerCashedOut =
    lastPlayerTransaction?.type === TransactionType.CASH_OUT &&
    lastPlayerTransaction.status === TransactionStatus.APPROVED;

  return (
    <>
      {!isPlayerCashedOut && (
        <>
          <Grid item xs={6}>
            <CardComponent
              icon={
                <img
                  style={{ width: 30, height: 30, marginBottom: -4 }}
                  src="/ic_stat_onesignal_default.png"
                  alt="chip icon"
                />
              }
              primary={totalBuyIn.chips}
              secondary={
                <>
                  Total Buy-in chips
                  <br />
                  <small>
                    (
                    {formatCurrency(totalBuyIn.chips * gameRate, game.currency)}
                    )
                  </small>
                </>
              }
            />
          </Grid>

          <Grid item xs={6}>
            <CardComponent
              icon={
                <span>
                  {formatCurrency(totalBuyIn.money, game.currency, false)}
                </span>
              }
              primary={totalBuyIn.money}
              secondary={
                <>
                  Total Buy-in <span style={{ color: "#63bc65" }}>real</span>{" "}
                  money
                  <span style={{ color: "transparent" }}>___</span>
                </>
              }
            />
          </Grid>
        </>
      )}
      {isPlayerCashedOut && (
        <Grid item xs={6}>
          <CardWinLostComponent
            amountWonLost={amountWonLost}
            isPlayerView={loggedInUser.id === user.id}
          />
        </Grid>
      )}
      {isPlayerCashedOut && (
        <Grid item xs={6}>
          <CardOwesComponent
            debtToTable={debtToTable}
            isPlayerView={loggedInUser.id === user.id}
          />
        </Grid>
      )}
      {totalBuyIn.chips > 0 && (
        <Grid item xs={6}>
          <CardComponent
            icon={<UploadIcon style={{ color: "red" }} />}
            primary={formatCurrency(totalBuyIn.money, game.currency)}
            secondary={
              <>
                Total buy-ins <br />({totalBuyIn.chips} chips)
              </>
            }
          />
        </Grid>
      )}

      {isPlayerCashedOut && lastPlayerTransaction && (
        <Grid item xs={6}>
          <CardComponent
            icon={<FileDownloadIcon style={{ color: "#63bc65" }} />}
            primary={formatCurrency(
              lastPlayerTransaction.amount,
              game.currency,
            )}
            secondary={
              <>
                Total cashed out
                <br />({lastPlayerTransaction.chips} chips)
              </>
            }
          />
        </Grid>
      )}
    </>
  );
}
