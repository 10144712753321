import { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import TransactionInterface from "@homegame/common/dist/interface/transaction.interface";
import TransactionService from "../core/service/transaction.service";
import { useAuth } from "../core/context/auth.context";
import dayjs from "dayjs";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import GameInterface from "@homegame/common/dist/interface/game.interface";
import ConfirmDialog from "../core/components/confirmation-dialog.component";
import TransactionStatus from "@homegame/common/dist/enum/transaction-status.enum";
import PaymentStatus from "./components/payment-status.component";
import TableComponent from "../core/components/table.component";
import Typography from "@mui/material/Typography";
import EventIcon from "@mui/icons-material/Event";
import { useSocket } from "../core/context/socket.context";
import { useToast } from "../core/hooks/use-toast.hook";
import formatCurrency from "../core/helpers/format-currency.helper";
import TableInterface from "@homegame/common/dist/interface/table.interface";
import TransactionFilterInterface from "@homegame/common/dist/interface/filters/transaction-filter.interface";
import { useSocketSubscription } from "../core/hooks/use-web-socket-subscription.hook";

const PAGE_SIZE = 5;

const useStyles = makeStyles(() =>
  createStyles({
    wrap: {
      minWidth: "100%",
      paddingLeft: 0,
      height: "100%",
      "& .MuiDataGrid-footerContainer": {
        border: "none",
      },
      "& .MuiDataGrid-cell": {
        border: "none",
        padding: 0,
        minWidth: 200,
      },
      "& .MuiDataGrid-columnHeaders": {
        border: "none",
      },
      "& .MuiDataGrid-root ": {
        border: "none",
      },
    },
    table: {
      "& .MuiDataGrid-row": {
        borderBottom: "1px solid rgba(0,0,0,.1)",
        paddingBottom: 10,
      },
    },
    title: {
      color: "#fff",
      fontSize: 14,
      fontWeight: 500,
      textShadow: "none",
    },
    heading: {
      fontSize: 10,
      width: "100%",
    },
    value: {
      width: "100%",
      fontSize: 13,
      color: "#47528c",
      textShadow: "none",
      fontWeight: 500,
      "& svg": {
        fontSize: 14,
        marginBottom: -3,
        // color: "#47528c",
      },
    },
    btnsContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      paddingTop: 5,
      paddingLeft: 10,
      paddingRight: 10,
      "& button": {
        textShadow: "none",
        minWidth: 80,
      },
    },
  }),
);

interface TransactionsListProps {
  game?: GameInterface;
  type?: TransactionType;
  userId?: number;
  toUserId?: number;
  pageSize?: number;
  showTableName?: boolean;
  showStatus?: boolean;
  height?: number | string;
  onActionDone?: () => void;
  filters?: TransactionFilterInterface;
}

export default function TransactionsListComponent({
  game,
  type,
  userId,
  toUserId,
  pageSize = PAGE_SIZE,
  showTableName = false,
  showStatus = false,
  height,
  filters = {},
  onActionDone,
}: TransactionsListProps) {
  const { showToast } = useToast();
  const classes = useStyles({});
  const transactionsService = new TransactionService();

  const { user } = useAuth();
  const { transactionSocket } = useSocket();

  const [refreshTrigger, setRefreshTrigger] = useState(1);

  const tableFilters: TransactionFilterInterface = {
    ...filters,
    take: pageSize,
    skip: 0,
    type,
    userId,
    toUserId,
    gameId: game?.id,
  };

  useEffect(() => {
    if (game) {
      setRefreshTrigger(refreshTrigger + 1);
    }
  }, [game]);

  const [transactionDialogState, setTransactionDialogState] = useState<{
    id: number | undefined;
    open: boolean;
    type: TransactionType;
    actionType: "approve" | "decline";
    confirmationMessage: string | undefined;
  }>({
    open: false,
    type: TransactionType.CASH_OUT,
    actionType: "approve",
    id: undefined,
    confirmationMessage: undefined,
  });

  const [isActionPending, setIsActionPending] = useState<boolean>(false);

  const getTableName = (table?: TableInterface) => {
    if (!table) return "";
    if (!table.deletedAt) return table.name;
    return (
      <>
        {`${table.name}`}
        <span style={{ color: "red" }}> (archived)</span>
      </>
    );
  };

  const getTransactionNameText = (transaction: TransactionInterface) => {
    if (userId) {
      if (transaction.type === TransactionType.PAYMENT) {
        if (userId === transaction.fromUserId)
          return (
            <>
              Return of debt to {transaction.toUser!.name}{" "}
              {showTableName ? <>in {getTableName(transaction.table)}</> : ""}
            </>
          );
        if (userId === transaction.toUserId)
          return (
            <>
              Return of debt from {transaction.fromUser!.name}{" "}
              {showTableName ? <>in {getTableName(transaction.table)}</> : ""}
            </>
          );
      }

      if (transaction.type === TransactionType.GAME_OFFSET) {
        const debts = transaction.debts;
        if (debts && debts.length > 0) {
          const fullyPaid = debts[0].originalAmount === transaction.amount;

          if (userId === transaction.toUserId)
            return (
              <>
                Debt from {transaction.fromUser!.name} of{" "}
                {formatCurrency(transaction.amount, debts[0].currency)} settled{" "}
                {!fullyPaid ? "partially" : ""}
                <br />
                through a win{" "}
                {showTableName ? <>in {getTableName(transaction.table)}</> : ""}
              </>
            );
          if (userId === transaction.fromUserId)
            return (
              <>
                Debt to {transaction.toUser!.name} of{" "}
                {formatCurrency(transaction.amount, debts[0].currency)} settled{" "}
                {!fullyPaid ? "partially" : ""}
                <br />
                through a win{" "}
                {showTableName ? <>in {getTableName(transaction.table)}</> : ""}
              </>
            );
        }
      }
      return (
        <>
          {transaction.type === TransactionType.BUY_IN ? (
            <span style={{ color: "red" }}>Buy-in</span>
          ) : (
            <span style={{ color: "#63bc65" }}>Cashout</span>
          )}{" "}
          {showTableName ? <>in {getTableName(transaction.table)}</> : ""}
        </>
      );
    }
    if (transaction.type === TransactionType.CASH_OUT)
      return (
        <>
          <span style={{ color: "#63bc65" }}>Cashout</span> from{" "}
          {transaction.toUserId === user?.id ? "you" : transaction.toUser!.name}
        </>
      );
    if (transaction.type === TransactionType.BUY_IN)
      return (
        <>
          <span style={{ color: "red" }}>Buy-in</span> from{" "}
          {transaction.fromUserId === user?.id
            ? "you"
            : transaction.fromUser!.name}
        </>
      );
    if (transaction.type === TransactionType.PAYMENT)
      return (
        <>
          Return of debt from{" "}
          {transaction.fromUserId === user?.id
            ? "you"
            : transaction.fromUser!.name}{" "}
          to {transaction.toUser!.name}
        </>
      );
    return <></>;
  };

  const onDialogClose = () => {
    setTransactionDialogState({
      id: undefined,
      confirmationMessage: undefined,
      open: false,
      type: TransactionType.CASH_OUT,
      actionType: "approve",
    });
  };

  const doAction = async () => {
    if (isActionPending) return;
    try {
      setIsActionPending(true);
      if (transactionDialogState.actionType === "approve") {
        await transactionsService.approve(transactionDialogState!.id as number);
      } else {
        await transactionsService.decline(transactionDialogState!.id as number);
      }
    } catch (e) {
      showToast((e as Error).message, "error");
    } finally {
      setIsActionPending(false);
      onDialogClose();
      setRefreshTrigger(refreshTrigger + 1);
      if (onActionDone) onActionDone();
    }
  };

  const handleDialogOpen = (
    transaction: TransactionInterface,
    actionType: "approve" | "decline",
  ) => {
    setTransactionDialogState({
      id: transaction.id,
      open: true,
      type: transaction.type,
      actionType,
      confirmationMessage: `Are you sure you want to ${actionType} a ${transaction.type} of ${transaction.chips} chips (${formatCurrency(transaction.amount!, transaction.currency!)})?`,
    });
  };

  const Buttons = ({ transaction }: { transaction: TransactionInterface }) => {
    if (transaction.status !== TransactionStatus.PENDING) return <></>;

    if (
      transaction.type === TransactionType.PAYMENT &&
      transaction.fromUserId === userId
    )
      return <></>;

    return (
      <div className={classes.btnsContainer}>
        <Button
          variant="contained"
          startIcon={<DoneIcon />}
          color="success"
          onClick={() => handleDialogOpen(transaction, "approve")}
        >
          Accept
        </Button>
        <Button
          variant="contained"
          startIcon={<ClearIcon />}
          color="error"
          onClick={() => handleDialogOpen(transaction, "decline")}
        >
          Decline
        </Button>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "",
      flex: 1,
      colSpan: 2,
      hideable: false,
      sortable: false,
      headerClassName: "hidden",
      renderCell: (params: GridRenderCellParams) => {
        const transaction: TransactionInterface = params.row;
        const chipsRealAmount = transaction.chips * transaction.rate;

        const transactionStatusDate = () => {
          if (transaction.type !== TransactionType.PAYMENT) return <></>;

          if (
            transaction.status === TransactionStatus.APPROVED &&
            transaction.approvedAt
          )
            return (
              <span style={{ display: "inline-block" }}>
                <CheckCircleOutlineIcon color="success" />{" "}
                {dayjs(transaction.approvedAt).format("MMMM D, YYYY h:mm A")}
              </span>
            );
          if (
            transaction.status === TransactionStatus.DECLINED &&
            transaction.declinedAt
          )
            return (
              <span>
                <BlockIcon color="error" />{" "}
                {dayjs(transaction.declinedAt).format("MMMM D, YYYY h:mm A")}
              </span>
            );
          return <></>;
        };

        return (
          <Grid container>
            <Grid item xs={8} justifyContent="space-between" display="flex">
              <Typography className={classes.title}>
                {getTransactionNameText(transaction)}
              </Typography>
            </Grid>
            <Grid item xs={4} justifyContent="space-between" display="flex">
              <PaymentStatus status={transaction.status} showIcons />
            </Grid>
            <Grid item xs={8} justifyContent="space-between" display="flex">
              <Typography className={classes.value}>
                <span>
                  {transaction.type === TransactionType.PAYMENT ? (
                    <ShortcutIcon />
                  ) : (
                    <EventIcon />
                  )}{" "}
                  {dayjs(transaction.createdAt).format("MMMM D, YYYY h:mm A")}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {![TransactionType.PAYMENT, TransactionType.GAME_OFFSET].includes(
                transaction.type,
              ) && (
                <Typography
                  className={classes.value}
                  style={{
                    color: "#47528c",
                  }}
                >
                  {Math.abs(transaction.chips)} chips{" "}
                  <small>
                    ({formatCurrency(chipsRealAmount, transaction.currency)})
                  </small>
                </Typography>
              )}
            </Grid>
            <Grid item xs={8} justifyContent="space-between" display="flex">
              <Typography className={classes.value}>
                {transactionStatusDate()}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                className={classes.value}
                style={{
                  color:
                    (transaction.amount === 0 ||
                      chipsRealAmount > transaction.amount) &&
                    transaction.type === TransactionType.BUY_IN
                      ? "rgba(245,0,87,.6)"
                      : "#47528c",
                }}
              >
                Money paid{" "}
                {formatCurrency(transaction.amount, transaction.currency)}
              </Typography>
            </Grid>
            <Grid item xs={8} justifyContent="space-between" display="flex">
              {showStatus && <Buttons transaction={transaction} />}
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const doRefresh = (data: any) => {
    if (
      [data.transaction.fromUserId, data.transaction.toUserId].includes(
        user?.id,
      )
    ) {
      setRefreshTrigger(refreshTrigger + 1);
    }
  };

  useSocketSubscription(transactionSocket?.connect(), [
    {
      eventName: "transactionUpdated",
      callback: doRefresh,
    },
    {
      eventName: "transactionCreated",
      callback: doRefresh,
    },
  ]);

  return (
    <Grid item xs={12} className={classes.wrap}>
      <TableComponent
        columns={columns}
        service={transactionsService}
        filters={tableFilters}
        height={height}
        className={classes.table}
        refresh={refreshTrigger}
      />

      <ConfirmDialog
        text={transactionDialogState.confirmationMessage}
        isLoading={isActionPending}
        actionText={`${transactionDialogState.actionType}`}
        open={transactionDialogState.open}
        onClose={onDialogClose}
        action={doAction}
      />
    </Grid>
  );
}
