import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UserDebtStatus from "@homegame/common/dist/enum/user-debt-status.enum";
import { capitalize } from "@mui/material";

interface PaymentStatusInterface {
  status?: UserDebtStatus;
  text?: string;
  payDate?: string | Date;
  showIcons?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      marginTop: 5,
      fontSize: 11,
      fontWeight: 300,
      height: 18,
      borderRadius: 5,
      clear: "both",
      maxWidth: "fit-content",
      "& .MuiChip-icon": {
        color: "#fff",
        fontSize: 11,
        marginTop: -1,
      },
    },
  }),
);

/**
 * Displays a Chip with the given status of a debt.
 *
 * @param {object} props Component props
 * @param {UserDebtStatus} props.status The status of the debt
 * @param {string|Date} [props.payDate] The date the debt was paid
 * @param {string} [props.text] A custom text to display on the chip
 * @param {boolean} [props.showIcons] Whether to show an icon on the chip
 *
 * @returns {React.ReactElement} A Chip with the given status
 */
export default function DebtStatus({
  status,
  payDate,
  text,
  showIcons,
}: PaymentStatusInterface) {
  const classes = useStyles({});

  const formattedText = text ? capitalize(text) : undefined;
  return (
    <>
      {status === UserDebtStatus.OPEN && (
        <Chip
          className={classes.chip}
          label={formattedText || "New"}
          color="info"
          icon={showIcons ? <WatchLaterIcon /> : undefined}
        />
      )}
      {status === UserDebtStatus.PENDING && (
        <Chip
          className={classes.chip}
          label={formattedText || "Pending"}
          color="warning"
          icon={showIcons ? <WatchLaterIcon /> : undefined}
        />
      )}
      {status === UserDebtStatus.CLOSED && (
        <Chip
          className={classes.chip}
          color="success"
          label={
            formattedText ||
            `Paid ${payDate ? `on ${dayjs(payDate).format("D MMMM")}` : ""}`
          }
          icon={showIcons ? <CheckCircleIcon /> : undefined}
        />
      )}
    </>
  );
}
