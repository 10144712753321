import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TransactionStatus from "@homegame/common/dist/enum/transaction-status.enum";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import { capitalize } from "@mui/material";

interface PaymentStatusInterface {
  status?: TransactionStatus;
  text?: string;
  payDate?: string | Date;
  showIcons?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      marginTop: 5,
      fontSize: 11,
      fontWeight: 300,
      height: 18,
      borderRadius: 5,
      clear: "both",
      maxWidth: "fit-content",
      "& .MuiChip-icon": {
        color: "#fff",
        fontSize: 11,
        marginTop: -1,
      },
    },
  }),
);

export default function PaymentStatus({
  status,
  payDate,
  text,
  showIcons,
}: PaymentStatusInterface) {
  const classes = useStyles({});

  const formattedText = text ? capitalize(text) : undefined;

  return (
    <>
      {!status && (
        <Chip
          className={classes.chip}
          label={formattedText || "unpaid"}
          color="error"
          icon={showIcons ? <ErrorIcon /> : undefined}
        />
      )}
      {status === TransactionStatus.PENDING && (
        <Chip
          className={classes.chip}
          label={formattedText || "pending"}
          color="warning"
          icon={showIcons ? <WatchLaterIcon /> : undefined}
        />
      )}
      {status === TransactionStatus.APPROVED && (
        <Chip
          className={classes.chip}
          color="success"
          label={
            formattedText ||
            `Accepted ${payDate ? `on ${dayjs(payDate).format("D MMMM")}` : ""}`
          }
          icon={showIcons ? <CheckCircleIcon /> : undefined}
        />
      )}
      {status === TransactionStatus.DECLINED && (
        <Chip
          className={classes.chip}
          label={formattedText || "Declined"}
          color="error"
          icon={showIcons ? <CancelIcon /> : undefined}
        />
      )}
    </>
  );
}
