import dayjs from "dayjs";
import { FunctionComponent } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { DateRange, DefinedRange } from "../types";

dayjs.extend(isSameOrBefore);

type DefinedRangesProps = {
  setRange: (range: DateRange) => void;
  setOtherRange: () => void;
  selectedRange: DateRange;
  ranges: DefinedRange[];
};

const isSameRange = (first: DateRange, second: DateRange) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;
  if (fStart && sStart && fEnd && sEnd) {
    return fStart.isSame(sStart) && fEnd.isSame(sEnd);
  }
  return false;
};

const DefinedRanges: FunctionComponent<DefinedRangesProps> = ({
  ranges,
  setRange,
  selectedRange,
  setOtherRange,
}: DefinedRangesProps) => (
  <List
    style={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    }}
  >
    {ranges.map((range, idx) => (
      <ListItem key={idx} onClick={() => setRange(range)}>
        <ListItemText
          primaryTypographyProps={{
            variant: "body2",
            style: {
              fontWeight: isSameRange(range, selectedRange) ? "bold" : "normal",
            },
          }}
        >
          {range.label}
        </ListItemText>
      </ListItem>
    ))}
    <ListItem onClick={setOtherRange}>
      <ListItemText
        primaryTypographyProps={{
          variant: "body2",
          style: {
            fontWeight: "normal",
          },
        }}
      >
        Custom range
      </ListItemText>
    </ListItem>
  </List>
);

export default DefinedRanges;
