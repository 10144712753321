import GamePlayerInterface from "@homegame/common/dist/interface/game-player.interface";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import GameService from "../../core/service/game.service";
import { useState } from "react";
import GamePlayerStatus from "@homegame/common/dist/enum/game-player-status.enum";
import GameStatus from "@homegame/common/dist/enum/game-status.enum";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmDialog from "../../core/components/confirmation-dialog.component";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useToast } from "../../core/hooks/use-toast.hook";
import { useGame } from "../game.context";

interface InviteControlsProps {
  currentGamePlayer: GamePlayerInterface;
  onPlayerStatusChange?: () => void;
  user: UserInterface | undefined;
}

const useStyles = makeStyles(() =>
  createStyles({
    inviteText: {
      color: "#1d2658",
      textShadow: "none",
      fontSize: 16,
    },
    wrapper: {
      backgroundColor: "rgba(255, 255, 255, 0.6)",
      padding: 15,
      borderRadius: 15,
      marginBottom: 15,
    },
  }),
);

export default function InviteControls({
  currentGamePlayer,
  user,
  onPlayerStatusChange,
}: InviteControlsProps) {
  const classes = useStyles();
  const { game, isCashier } = useGame();
  const gameService = new GameService();
  const { showToast } = useToast();

  const [isPlayerRegistering, setIsPlayerRegistering] =
    useState<boolean>(false);
  const [registerConfirmation, setRegisterConfirmation] =
    useState<boolean>(false);

  const [isPlayerUnregistering, setIsPlayerUnregistering] =
    useState<boolean>(false);
  const [unregisterConfirmation, setUnregisterConfirmation] =
    useState<boolean>(false);

  // todo: refactor conditions
  if (!user || !game) return;

  if (
    !currentGamePlayer.invitedByCashier ||
    (currentGamePlayer?.status === GamePlayerStatus.REGISTERED &&
      game.status === GameStatus.ONGOING)
  )
    return;

  const tableIsFull = game.players
    ? game.players.filter((gp) => gp.status === GamePlayerStatus.REGISTERED)
        .length >= game.playersLimit
    : false;

  if (
    tableIsFull &&
    currentGamePlayer.status !== GamePlayerStatus.REGISTERED &&
    game.status !== GameStatus.FINISHED
  ) {
    let text = "You cannot register to the game - the table is full";
    if (isCashier) text = "Player cannot be registered - the table is full";
    return (
      <Grid
        item
        xs={12}
        textAlign="center"
        justifyContent="space-around"
        display="flex"
        p={1}
      >
        <Typography className={classes.inviteText}>{text}</Typography>
      </Grid>
    );
  }

  const closeRegisterConfirmation = () => setRegisterConfirmation(false);
  const openRegisterConfirmation = () => {
    setRegisterConfirmation(true);
  };

  const closeUnregisterConfirmation = () => setUnregisterConfirmation(false);
  const openUnregisterConfirmation = () => {
    setUnregisterConfirmation(true);
  };

  const handlePlayerRegister = async () => {
    if (!user) return;
    setIsPlayerRegistering(true);
    try {
      await gameService.acceptInvite(game.id, user?.id);
      showToast("Player registered", "success");
      if (onPlayerStatusChange) onPlayerStatusChange();
      closeRegisterConfirmation();
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsPlayerRegistering(false);
    }
  };

  const handlePlayerUnregister = async () => {
    if (!user) return;
    setIsPlayerUnregistering(true);
    try {
      await gameService.rejectInvite(game.id, user?.id);
      showToast("Player unregistered", "warning");
      if (onPlayerStatusChange) onPlayerStatusChange();
      closeUnregisterConfirmation();
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsPlayerUnregistering(false);
    }
  };

  const getInviteText = () => {
    const isWas = game.status === GameStatus.FINISHED ? "was" : "is";

    if (currentGamePlayer.status === GamePlayerStatus.INVITED) {
      if (isCashier)
        return `${currentGamePlayer.player?.name} was invited by ${
          game.table?.user?.name
        } to register to a game on ${dayjs(game.date).format(
          "MMMM DD, YYYY",
        )} in ${game.table?.name} table`;
      return `${
        game.table?.user?.name
      } has invited you to register to a game on ${dayjs(game.date).format(
        "MMMM DD, YYYY",
      )} in ${game.table?.name} table`;
    } else if (currentGamePlayer.status === GamePlayerStatus.REGISTERED) {
      if (isCashier)
        return `${
          currentGamePlayer.player?.name
        } ${isWas} registered to a game on ${dayjs(game.date).format(
          "MMMM DD, YYYY",
        )} in ${game.table?.name} table`;
      return `You have registered to a game on ${dayjs(game.date).format(
        "MMMM DD, YYYY",
      )} in ${game.table?.name} table`;
    } else {
      if (isCashier)
        return `${
          currentGamePlayer.player?.name
        } ${isWas} not registered to a game on ${dayjs(game.date).format(
          "MMMM DD, YYYY",
        )} in ${game.table?.name} table`;
      return `You have not registered to a game on ${dayjs(game.date).format(
        "MMMM DD, YYYY",
      )} in ${game.table?.name} table`;
    }
  };
  return (
    <Grid item xs={12}>
      <div className={classes.wrapper}>
        <Grid
          item
          xs={12}
          textAlign="center"
          justifyContent="space-around"
          display="flex"
          p={1}
        >
          <Typography className={classes.inviteText}>
            {getInviteText()}
          </Typography>
        </Grid>

        {game.status !== GameStatus.FINISHED && (
          <Grid
            item
            xs={12}
            textAlign="center"
            justifyContent="space-around"
            display="flex"
            p={1}
          >
            {currentGamePlayer?.status !== GamePlayerStatus.REGISTERED && (
              <Button
                variant="contained"
                color="success"
                onClick={openRegisterConfirmation}
                startIcon={<CheckIcon />}
              >
                Register
              </Button>
            )}
            {currentGamePlayer?.status !== GamePlayerStatus.UNREGISTERED && (
              <Button
                variant="contained"
                color="error"
                onClick={openUnregisterConfirmation}
                startIcon={<CancelIcon />}
              >
                Decline
              </Button>
            )}
          </Grid>
        )}

        <ConfirmDialog
          text={
            isCashier
              ? `Are you sure you want to register ${user.name}?`
              : "Are you sure you want to register?"
          }
          isLoading={isPlayerRegistering}
          actionText={isCashier ? "Register player" : "Register"}
          open={registerConfirmation}
          onClose={closeRegisterConfirmation}
          action={handlePlayerRegister}
        />

        <ConfirmDialog
          text={
            isCashier
              ? `Are you sure you want to unregister ${user.name}?`
              : "Are you sure you want to unregister?"
          }
          isLoading={isPlayerUnregistering}
          actionText={isCashier ? "Unregister player" : "Unregister"}
          open={unregisterConfirmation}
          onClose={closeUnregisterConfirmation}
          action={handlePlayerUnregister}
        />
      </div>
    </Grid>
  );
}
